import styled from 'styled-components';
import { fontMedium } from '../../assets/styles/typography';
import { colors } from '../colors';

const Col = styled.div`
	box-sizing: border-box;
	width: 100%;
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	max-width: ${(props) => props.maxWidth};
	min-width: ${(props) => props.minWidth};
	padding: ${(props) => props.padding};
	margin: ${(props) => props.margin};
	overflow: ${(props) => props.overflow};
	text-align: ${(props) => props.align};
	img {
		max-width: 100%;
	}
	${({ noteResponseButton }) =>
		noteResponseButton &&
		`@media screen and (max-width: 768px) {
			flex-direction: column;
			> button {
				margin: 7px 0;
			}
        }
    `};

	${({ flexWrap }) =>
		flexWrap &&
		`display: flex;
		flex-wrap: wrap;
	`};
	${({ Attachments }) =>
		Attachments &&
		`
	border: 1px dashed #3263D6;
	border-radius: 4px;
	height: 100px!important;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	span{
		color:  ${colors.BlueBase};
		font-size: 16px;
		margin:-2px 0 0 0;
	}
	h1{
		color:  ${colors.BlueBase};
		font-size: 14px;
		line-height: 15px;
		margin:0px 0 0 5px;
		font-family: ${fontMedium.familyName};
		span{
			color: #6F81A2;;
			font-size: 14px;
			line-height: 14px;
		}
	}

	input[type="file"]{
		width: 100%;
		height: 100px;
		position: absolute;
		left:0;
		top: 0;
		opacity:0;
	}
	`}

	${({ ChartScroll }) =>
		ChartScroll &&
		`@media screen and (max-width: 991px) {
			overflow: auto;
			> div{
				margin: 0 auto;
			}
        }
    `};
	${({ reasonForConsultation }) =>
		reasonForConsultation &&
		`width:100%!important;
		margin:12px 0 0 0;
	`}
	${({ RemoveChartValue }) =>
		RemoveChartValue &&
		`.recharts-default-legend {
            display:none;
        }
    `};
	${({ rightAlign }) =>
		rightAlign &&
		`justify-content: flex-end;
		text-align: right;
		display: flex;
    `};

	${({ leftAlign }) => leftAlign && ` text-align: left; display: flex;`};

	${({ RightBorder }) =>
		RightBorder &&
		`border-right: 1px solid ${colors.Border2};
`};
	${({ goCenter, needMargin }) =>
		goCenter &&
		`width: 50%;
        ${needMargin && `margin: auto !important;`}
        @media screen and (max-width: 767px) {
            width: 100% !important;

        }
    `};
	${({ capitalize }) =>
		capitalize &&
		`text-transform: capitalize;
    `};
	${({ FlexCenter }) =>
		FlexCenter &&
		`justify-content:center;
        align-items:center;
    `};

	${({ FlexColumn }) =>
		FlexColumn &&
		`flex-direction: column;
		display: flex;
	`};
	${({ btnResponsive }) =>
		btnResponsive &&
		`@media screen and (max-width: 600px) {
			flex-direction: column;
			button{
				margin: 0px;
				+ button{
					margin-top:15px;
				}
			}
			button{
				padding:0.25rem 10px;
				font-size: 13px;
				font-family: ${fontMedium.familyName};
				+ button{
					margin-left:5px;
				}
			}
		}
    `};

	${({ centerAlign }) =>
		centerAlign &&
		`justify-content: center;
	`}
	${({ IconButtonCol }) =>
		IconButtonCol &&
		`button {
			min-width: 34px;
			text-align: center;
			align-items: center;
			justify-content: center;
			span{
				color: ${colors.BlueBase};
				font-size: 14px;
			}
		}
	`};
	${({ FilterApplied }) =>
		FilterApplied &&
		`button {
			position:relative;
			span{
				&:after{
					position: absolute;
					right: -6px;
					top:-6px;
					content: '';
					width:6px;
					height: 6px;
					border-radius: 20px;
					background: ${colors.BlueLight};
				}
			}
		}
	`};
	${({ IconButtonColSmall }) =>
		IconButtonColSmall &&
		`button {
			span{
				font-size: 15px;
			}
		}
    `};
	${({ linkButton }) =>
		linkButton &&
		`span{
			background: #FFEDC9;
            border: 1px solid #FFCB67;
            box-sizing: border-box;
            border-radius: 4px;
            color: #3E2F21;
            text-align: center;
            padding: 2px 10px;
            font-size: 14px !important;
		}
	`};
	${({ RightDropdownMobile }) =>
		RightDropdownMobile &&
		`@media screen and (max-width: 767px) {
			button + div{
				right: inherit;
				left: 0px;
			}
		}
	`};
	${({ ClinicDropdownMobile }) =>
		ClinicDropdownMobile &&
		`@media screen and (max-width: 860px) {
			padding-top:10px;
		}
	`};
	${({ LinkText }) =>
		LinkText &&
		`span{
			font-size: 16px;
			line-height: 22px;
			font-family: ${fontMedium.familyName};
			color: ${colors.BlueLight};
			text-decoration: none;
			transition: all ease 0.4s;
			white-space: nowrap;
			:hover{
				color: ${colors.BlueBase};
				cursor: pointer;
			}
		}
	`};

	${({ middleAlign }) =>
		middleAlign &&
		`align-items: center;
		display: flex;
	`};
	${({ ResponsiveCol }) =>
		ResponsiveCol &&
		`@media screen and (max-width: 767px) {
			width: 100%!important
		}
		`};
	${({ chatListDetails }) =>
		chatListDetails &&
		`display: flex;
		flex-direction: column;
		justify-content: center;
		max-width:calc(100% - 110px)
	`};
	${({ chatListDetailsLarge }) =>
		chatListDetailsLarge &&
		`display: flex;
		flex-direction: column;
		justify-content: center;
		max-width:calc(100% - 60px)
	`};
	${({ hideChatBack }) =>
		hideChatBack &&
		`@media screen and (min-width: 768px) {
			display: none;
		}
	`};
	${({ plusDropdown }) =>
		plusDropdown &&
		`button{
			width:48px;
			height:48px;
			padding:0px;
			+ div{
				left:0;
				bottom: 48px;
				right: inherit;
				top: inherit;
				span{
					font-size: 16px;
					color: ${colors.BlueLight};
				}
			}
			span{
				font-size: 48px;
				color: #8C8C8C;
				@media screen and (max-width: 768px) {
					font-size: 42px!important;
				}
			}
			:hover{
				background: transparent;
				span{
					color: ${colors.BlueLight};
				}
			}
			:focus{
				background: transparent;
			}
		}
		@media screen and (max-width: 768px) {
			width:55px!important;
		}
		`};

	${({ ColMDHalf }) =>
		ColMDHalf &&
		`@media screen and (max-width: 1200px) {
			width: 50%;
			float:left;
			margin-bottom: 24px;
		}
		@media screen and (max-width: 767px) {
			width: 100%;
			padding:0;
		}
	`};
	${({ ColMDFull }) =>
		ColMDFull &&
		`@media screen and (max-width: 1200px) {
			width: 100%;
			max-width: 100%;
			float:left;
			padding:0;
		}
	`};
	${({ ColSMFull }) =>
		ColSMFull &&
		`@media screen and (max-width: 991px) {
			width: 100%;
			float:left;
			padding:0;
			max-width: 100%;
		}
	`};
	${({ ColXSFull }) =>
		ColXSFull &&
		`@media screen and (max-width: 767px) {
			width: 100%;
			max-width: 100%;
			float:left;
			padding:0;
		}
	`};
	${({ ColMDMB }) =>
		ColMDMB &&
		`@media screen and (max-width: 1200px) {
			margin-bottom:24px;
		}
	`};
	${({ ColMDMBS }) =>
		ColMDMBS &&
		`@media screen and (max-width: 1200px) {
			margin-bottom:15px;
		}
	`};
	${({ ColSMMB }) =>
		ColSMMB &&
		`@media screen and (max-width: 991px) {
			margin-bottom:24px;
		}
	`};
	${({ ColXSMB }) =>
		ColXSMB &&
		`@media screen and (max-width: 767px) {
			margin-bottom:15px;
		}
	`};
	${({ ColXSMT }) =>
		ColXSMT &&
		`@media screen and (max-width: 767px) {
		margin-top:15px;
	}
`};
	${({ ColRBorder }) =>
		ColRBorder &&
		`margin-right: 20px;
		border-right: 1px solid ${colors.Border2};
	`};
	${({ SelectedBadge }) =>
		SelectedBadge &&
		`width: auto;
		padding: 4px 10px;
		border-radius: 10px;
		background: #eff3ff;
		margin-top: 8px;
		font-size: 13px;
        border: 1px solid #e5e9f3;
        position: fixed;
        z-index: 999;
		span{
			font-family:${fontMedium.familyName};
		}
    `};
	${({ buttonEffect }) =>
		buttonEffect &&
		`width: auto;
		padding: 4px 10px;
		border-radius: 4px;
		background: #E4E9F4;
        margin-top: 8px;
        margin-right: 8px;
        //font-weight: 600;
        font-size: 12px;
        border: 1px solid #E4E9F4;
        color:#57668F;
        display: inline-block;
        span{
            color: #1E3269;
            margin-right: 2px;
        }
        @media screen and (max-width: 480px) {
			width: 100%;
		}
	`};

	${({ ColTBPad }) =>
		ColTBPad &&
		`padding-bottom: 10px;
		padding-top: 10px;
		@media screen and (max-width: 991px) {
			padding-bottom: 0px;
			padding-top: 0px;
		}
	`};
	${({ AttachemntImage }) =>
		AttachemntImage &&
		`border: 1px solid ${colors.Border2};
		background: ${colors.White};
		display: flex;
		align-items: center;
		justify-content:center;
		border-radius: 6px;
		padding: 20px;
		img{
			max-width: 100%;
			max-height: 100%;
		}
`};
	${({ RotateAnimate }) =>
		RotateAnimate &&
		`animation: rotation 2s infinite linear;
		@keyframes rotation {
			from {
			  transform: rotate(0deg);
			}
			to {
			  transform: rotate(359deg);
			}
		  }
	`};
	${({ AttchmentDelete }) =>
		AttchmentDelete &&
		`position:absolute;
		right: 24px;
		top: 24px;
		width:18px;
		height: 18px;
		text-align: center;
		border-radius: 20px;
		background: ${colors.Grey8};
		color: ${colors.White};
		font-size: 8px;
		padding: 4px 0;
		cursor: pointer;
		transition: all ease-in 0.4s;
		&:hover{
			background: ${colors.BlueBase};
		}
	`};
	${({ CountryCode }) =>
		CountryCode &&
		`div{
			width:100%;
		}
		button{
			display: flex;
			width: 100%;
			align-items: center;
			background: ${colors.White};
			border: 1px solid ${colors.BorderColorTB};
			border-radius: 6px;
			height: 48px;
			padding: 0 16px;
			font-size: 14px;
			transition: ease-in all 0.4s;
			box-sizing: border-box;
			color: #aaaaaa;
			font-family: ${fontMedium.familyName};
			font-size: 14px;
			text-align: left;
			position: relative;
			&:hover{
				background: ${colors.White};
			}
			@media screen and (max-width: 767px) {
				padding: 16px 10px;
			}
			&:focus {
				outline: none;
				border: 1px solid ${colors.BlueBase};
				background: ${colors.White};
			}
			&:after{
				content: '\\e900';
				position: absolute;
				right: 10px;
				top: 18px;
				font-family: 'iconFont' !important;
				color: #aaaaaa;
				font-size: 11px;
			}
			span{
				padding-top: 3px;
				span{
					color: ${colors.BlueBase};
					font-family: ${fontMedium.familyName};
					font-size: 14px;
					position: relative;
					top: -9px;
					left: 5px;
				}
			}
			+ div{
				width: 100%;
				div{
					display: flex;
					align-items: center;
					img{
						margin-right: 10px;
					}
				}
			}
		}
		> div{
			width: 100%;
		}
    `};
	${({ grid }) =>
		grid &&
		`display: inline-block;
			width: 32% !important;
			padding: 8px 2px;
			@media screen and (max-width: 767px) {
				width: 100% !important
			}
	`};
	${({ applyBorderBottom }) => applyBorderBottom && `border-bottom: 1px solid #536DFE;`}
	${({ applyJournalBorderBottom }) =>
		applyJournalBorderBottom && `border-bottom: 2.5px solid #536DFE;`}
		${({ SearchClinicalDocs }) =>
		SearchClinicalDocs &&
		`display:flex;
		@media screen and (max-width: 767px){
			button{
				span{
					padding-right:0;
				}
				div{
					display:none;
				}
			}
		}

	`}
`;

export default Col;
