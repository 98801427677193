import styled from 'styled-components';

const ModalBody = styled.div`
	padding: 30px 24px 30px 24px;
	padding: ${(props) => props.padding};
	overflow: scroll;
	max-height: 450px;
	@media screen and (max-width: 360px) {
		max-height: 350px;
	}
	${({ BodyScroll }) =>
		BodyScroll &&
		`min-height:55vh;
		max-height:55vh;
        overflow: auto;
        height:100%;
    `};
	${({ autoHeight }) =>
		autoHeight &&
		`min-height:55vh;
        overflow: auto;
        height: calc(100vh - 100px);
        margin-bottom: 30px;
    `}
`;

export default ModalBody;
