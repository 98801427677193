import { GET, POST, PUT, PATCH, DELETE } from '../../service/HttpService';
import { errorHandling, showMessage } from '../../components/Toast/toast.store';
// import { PER_PAGE } from '../../helpers/Constants';
import { DeleteDataById, GetData } from '../../service/firebaseService';
import { getLocalStorageValueAsJSON } from '../Login/login.store';

const FILE = 'FILE://PATIENT_MANAGEMENT';

const generateActionType = (type) => `${FILE}/${type}`;

// Action Types
const GET_LIST = generateActionType('GET_LIST');
const GET_DETAILS = generateActionType('GET_DETAILS');
const BEFORE_GET_LIST = generateActionType('BEFORE_GET_LIST');
const GET_LIST_PAGINATION = generateActionType('GET_LIST_PAGINATION');
const STORE_KEYWORDS = generateActionType('STORE_KEYWORDS');
const GET_PATIENT_LIST = generateActionType('GET_PATIENT_LIST');
const BEFORE_GET_PATIENT_LIST = generateActionType('BEFORE_GET_PATIENT_LIST');
const GET_STATUS = generateActionType('GET_STATUS');
const CLEAR_ALL = generateActionType('CLEAR_ALL');
const CLEAR_STATUS = generateActionType('CLEAR_STATUS');
const UPDATE_LIST = generateActionType('UPDATE_LIST');
const STORE_PARAMS = generateActionType('STORE_PARAMS');

// Reducer
const initialState = {
	initialList: true,
	fetching: false,
	dataLoader: true,
	detailsFetching: false,
	list: [],
	patientList: [],
	details: {},
	listOffset: 0,
	keywords: '',
	params: '',
	statusCode: 0,
	noOfResults: 0,
	totalResults: 0,
};

export function clearAll() {
	return function (dispatch) {
		dispatch({ type: CLEAR_ALL });
	};
}

export function clearStatus() {
	return function (dispatch) {
		dispatch({ type: CLEAR_STATUS });
	};
}
export function searchKeyword(searchKeywords = '') {
	return function (dispatch) {
		dispatch({ type: STORE_KEYWORDS, keyword: searchKeywords });
	};
}

export function setParamsAction(params = []) {
	return function (dispatch) {
		dispatch({ type: STORE_PARAMS, params });
	};
}

export function getUpdatedList(data = {}) {
	const { id = '' } = data;

	const getConfig = { url: `patients/${id}` };
	return async function (dispatch) {
		try {
			const { data = {} } = await GET(getConfig);
			dispatch({ type: UPDATE_LIST, data, id });
		} catch (error) {
			dispatch(errorHandling(error));
		}
	};
}

export function getListAction(data) {
	const { searchKeyword = '', params = {} } = data || {};

	// const initialLists = initialList;

	// const getConfig = {
	// 	params: { ...params },
	// 	url: `patients?limit=${limit}&offset=${offset}&q=${searchKeyword}`,
	// };
	return async function (dispatch) {
		try {
			const res = await GetData('Patients', searchKeyword, params);
			console.log('Patient List', res);
			dispatch({
				type: GET_LIST,
				data: res,
				noOfResults: res.length,
				totalResults: res.length,
				dataLoader: false,
			});
		} catch (error) {
			console.log('Patient List--error', error);
			dispatch({
				type: GET_LIST,
				data: [],
				listOffset: 0,
				initialList: true,
				dataLoader: false,
			});
			dispatch(errorHandling(error));
		}
	};
}

export const deletePatient = async (patientId) => {
	console.log('I am worked --- PATIENT ID', patientId);
	const clinicId = await getLocalStorageValueAsJSON('clinicId');
	const res = await DeleteDataById(`Clinics/${clinicId}/Patients`, patientId);
	return res;
};

export function specialCategory({ id = 0, isSpecialCategory }) {
	const message = isSpecialCategory ? '' : 'non';
	const getConfig = { data: { isSpecialCategory }, url: `/patients/${id}/special` };
	return async function (dispatch) {
		try {
			const { status = 0 } = await PATCH(getConfig);
			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: {
						0: { message: `Patient successfully marked as ${message} special category`, key: '' },
					},
				})
			);
			dispatch(getUpdatedList({ id }));
		} catch (error) {
			dispatch(errorHandling(error));
		}
	};
}

export function getDetails(id = 0) {
	const getConfig = { url: `patient/${id}` };
	const detailsFetching = true;
	return async function (dispatch) {
		try {
			const { data = {} } = await GET(getConfig);
			dispatch({ type: GET_DETAILS, data, detailsFetching });
		} catch (error) {
			const data = {};
			dispatch({ type: GET_DETAILS, data, detailsFetching });
		}
	};
}

export function linkDoctor(id = '', doctorID = '') {
	const postConfig = { url: `patients/${id}/doctors/${doctorID}` };
	return async function (dispatch) {
		try {
			const { status = 0 } = await PUT(postConfig);
			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: { 0: { message: 'Patient successfully linked to the doctor', key: '' } },
				})
			);
			dispatch(getUpdatedList({ id }));
		} catch (error) {
			dispatch(errorHandling(error));
		}
	};
}

export function linkCoach(id = '', doctorID = '') {
	const postConfig = { url: `patients/${id}/coaches/${doctorID}` };
	return async function (dispatch) {
		try {
			const { status = 0 } = await PUT(postConfig);
			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: { 0: { message: 'Patient successfully linked to the coach', key: '' } },
				})
			);
			dispatch(getUpdatedList({ id }));
		} catch (error) {
			dispatch(errorHandling(error));
		}
	};
}

export function unLinkCoach(id = '', coachID = '') {
	const delConfig = { url: `patients/${id}/coaches/${coachID}` };
	return async function (dispatch) {
		try {
			const { status = 0 } = await DELETE(delConfig);
			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: {
						0: { message: 'Patient successfully unlinked to the coach', key: '' },
					},
				})
			);
		} catch (error) {
			dispatch(errorHandling(error));
		}
	};
}

export function unLinkDoctor(id = '', doctorID = '') {
	const delConfig = { url: `patients/${id}/doctors/${doctorID}` };
	return async function (dispatch) {
		try {
			const { status = 0 } = await DELETE(delConfig);
			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: {
						0: { message: 'Patient successfully unlinked to the doctor', key: '' },
					},
				})
			);
		} catch (error) {
			dispatch(errorHandling(error));
		}
	};
}

export function create(datas = {}) {
	const postConfig = { data: datas, url: `patients` };
	return async function (dispatch) {
		try {
			const { status = 0 } = await POST(postConfig);
			dispatch(getListAction());
			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: { 0: { message: 'Record successfully added', key: '' } },
				})
			);
		} catch (error) {
			dispatch(errorHandling(error));
		}
	};
}

export function edit(datas = {}, id = 0) {
	const filterDatas = datas;
	delete filterDatas.email;
	delete filterDatas.phone;
	delete filterDatas.countryCode;

	const postConfig = { data: filterDatas, url: `patients/${id}` };
	return async function (dispatch) {
		try {
			const { status = 0 } = await PUT(postConfig);
			dispatch(getListAction());
			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: { 0: { message: 'Record successfully updated', key: '' } },
				})
			);
		} catch (error) {
			dispatch(errorHandling(error));
		}
	};
}

export function deleteUser(id = 0) {
	const delConfig = { url: `patients/${id}` };
	return async function (dispatch) {
		try {
			const { status = 0 } = await DELETE(delConfig);
			dispatch(getListAction());
			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: {
						0: { message: 'Patient successfully deleted', key: '' },
					},
				})
			);
		} catch (error) {
			dispatch(errorHandling(error));
		}
	};
}

function patientManagementReducer(state = initialState, action) {
	switch (action.type) {
		case GET_STATUS: {
			return {
				...state,
				statusCode: action.status,
			};
		}
		case STORE_KEYWORDS:
			return {
				...state,
				keywords: action.keyword,
			};
		case STORE_PARAMS:
			return {
				...state,
				params: action.params,
			};
		case BEFORE_GET_LIST:
			return {
				...state,
				dataLoader: action.dataLoader,
			};

		case UPDATE_LIST: {
			const { data = {}, /* requestType = '', */ id = '' } = action;
			const { list = [] } = state;
			if (Object.keys(data).length !== 0 && id !== '') {
				list.forEach((d, i) => {
					if (d.patientId === id) {
						list[i] = data;
						return true;
					}
				});
			}

			return { ...state };
		}

		case GET_LIST: {
			return {
				...state,
				list: action.data,
				initialList: action.initialList,
				dataLoader: action.dataLoader,
				fetching: true,
				listOffset: 0,
				noOfResults: 1,
				totalResults: action.totalResults,
				/* statusCode: 0, */
			};
		}
		case GET_LIST_PAGINATION: {
			const { data } = action;
			return {
				...state,
				list: [...state.list, ...data],
				initialList: action.initialList,
				dataLoader: action.dataLoader,
				fetching: true,
				listOffset: action.offset,
				noOfResults: action.noOfResults,
				totalResults: action.totalResults,
			};
		}
		case GET_DETAILS:
			return {
				...state,
				details: action.data,
				detailsFetching: action.detailsFetching,
			};
		case BEFORE_GET_PATIENT_LIST:
			return {
				...state,
				patientFetching: action.patientFetching,
			};
		case GET_PATIENT_LIST:
			return {
				...state,
				patientList: action.data,
				patientFetching: action.patientFetching,
			};

		case CLEAR_STATUS:
			return {
				...state,
				statusCode: 0,
			};

		case CLEAR_ALL:
			return {
				...state,
				initialList: true,
				fetching: false,
				dataLoader: true,
				detailsFetching: false,
				list: [],
				patientList: [],
				details: {},
				listOffset: 0,
				keywords: '',
				params: '',
				statusCode: 0,
				noOfResults: 0,
				totalResults: 0,
			};

		default:
			return state;
	}
}

export default patientManagementReducer;
