import styled from 'styled-components';

const Modal = styled.div`
	transition: ease-in all 0.4s;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	background: rgba(0, 14, 27, 0.5);
	display: none;
	align-items: center;
	justify-content: center;
	${({ show }) =>
		show &&
		`display: flex;
	`}
`;

export default Modal;
