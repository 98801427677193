import { GET, POST, PUT, DELETE } from '../../service/HttpService';
import { errorHandling, showMessage } from '../../components/Toast/toast.store';
// import { PER_PAGE } from '../../helpers/Constants';

import { isUnauthorized } from '../Login/login.store';
import { GetList } from '../../service/firebaseService';

const FILE = 'FILE://CLINIC_MANAGEMENT';

const generateActionType = (type) => `${FILE}/${type}`;

// Action Types
const GET_LIST = generateActionType('GET_LIST');
const GET_DETAILS = generateActionType('GET_DETAILS');
const BEFORE_GET_LIST = generateActionType('BEFORE_GET_LIST');
const GET_LIST_PAGINATION = generateActionType('GET_LIST_PAGINATION');
const STORE_KEYWORDS = generateActionType('STORE_KEYWORDS');
const GET_PATIENT_LIST = generateActionType('GET_PATIENT_LIST');
const BEFORE_GET_PATIENT_LIST = generateActionType('BEFORE_GET_PATIENT_LIST');
const GET_STATUS = generateActionType('GET_STATUS');
const CLEAR_ALL = generateActionType('CLEAR_ALL');
const UPDATE_LIST = generateActionType('UPDATE_LIST');
const CLEAR_STATUS = generateActionType('CLEAR_STATUS');

// Reducer
const initialState = {
	initialList: true,
	fetching: false,
	dataLoader: true,
	detailsFetching: false,
	list: [],
	patientList: [],
	details: {},
	listOffset: 0,
	keywords: '',
	statusCode: 0,
	noOfResults: 0,
	totalResults: 0,
};

export function clearAll() {
	return function (dispatch) {
		dispatch({ type: CLEAR_ALL });
	};
}
export function clearStatus() {
	return function (dispatch) {
		dispatch({ type: CLEAR_STATUS });
	};
}
export function searchKeyword(searchKeywords = '') {
	return function (dispatch) {
		dispatch({ type: STORE_KEYWORDS, keyword: searchKeywords });
	};
}

export function updateList(data = {}) {
	const { requestType = '', id = '' } = data;
	const getConfig = { url: `clinics/${id}` };
	return async function (dispatch) {
		try {
			if (id === '' && requestType === '') return true;
			const { data = {} } = await GET(getConfig);
			dispatch({ type: UPDATE_LIST, data, requestType, id });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			dispatch(errorHandling(error));
		}
	};
}

export function getListAction(data = {}) {
	const {
		// limit = PER_PAGE,
		offset = 0,
		searchKeyword = '',
		initialList = true,
	} = data;

	const initialLists = initialList;

	// const getConfig = { url: `clinics?limit=${limit}&offset=${offset}&search=${searchKeyword}` };
	return async function (dispatch) {
		try {
			dispatch({ type: STORE_KEYWORDS, searchKeyword });
			// initialLists = !pagination;

			// const { data = {} } = await GET(getConfig);
			// const { results = {}, noOfResults = 0, totalResults = 0 } = data;
			const data = await GetList('Clinics', searchKeyword);
			dispatch({
				type: GET_LIST,
				data,
				noOfResults: data.length,
				totalResults: data.length,
				offset,
				initialList: initialLists,
				dataLoader: false,
			});
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			const data = {};
			dispatch({
				type: GET_LIST,
				data,
				listOffset: 0,
				initialList: true,
				dataLoader: false,
			});
		}
	};
}

export function getDetails(id = 0) {
	const getConfig = { url: `clinics/${id}` };
	const detailsFetching = true;
	return async function (dispatch) {
		try {
			const { data = {} } = await GET(getConfig);
			dispatch({ type: GET_DETAILS, data, detailsFetching });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			const data = {};
			dispatch({ type: GET_DETAILS, data, detailsFetching });
		}
	};
}

export function getPatientList(keyword = '') {
	const getConfig = { url: `discrete/patients?with=clinic&limit=100&offset=0&search=${keyword}` };
	return async function (dispatch) {
		try {
			dispatch({ type: BEFORE_GET_PATIENT_LIST, patientFetching: true });
			const { data = {} } = await GET(getConfig);
			dispatch({ type: GET_PATIENT_LIST, data, patientFetching: false });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			const data = {};
			dispatch({ type: GET_PATIENT_LIST, data, patientFetching: false });
		}
	};
}

export function linkPatient(id = '', patientID = '') {
	const postConfig = { url: `clinics/${id}/patients/${patientID}` };
	return async function (dispatch) {
		try {
			const { status = 0 } = await PUT(postConfig);
			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: { 0: { message: 'Patient successfully linked to the clinic', key: '' } },
				})
			);
			dispatch(getPatientList());
			dispatch(getListAction());
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			dispatch(errorHandling(error));
		}
	};
}

export function unLinkPatient(id = '', patientID = '') {
	const delConfig = { url: `clinics/${id}/patients/${patientID}` };
	return async function (dispatch) {
		try {
			const { status = 0 } = await DELETE(delConfig);
			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: {
						0: { message: 'Patient successfully unlinked to the clinic', key: '' },
					},
				})
			);
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			dispatch(errorHandling(error));
		}
	};
}

export function create(datas = {}) {
	const postConfig = { data: datas, url: `clinics` };
	return async function (dispatch) {
		try {
			const { status = 0 } = await POST(postConfig);
			dispatch(getListAction());
			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: { 0: { message: 'Record successfully added', key: '' } },
				})
			);
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			dispatch(errorHandling(error));
		}
	};
}

export function edit(datas = {}, id = 0) {
	const filterDatas = datas;
	delete filterDatas.email;
	delete filterDatas.phone;
	delete filterDatas.countryCode;

	const postConfig = { data: filterDatas, url: `clinics/${id}` };
	return async function (dispatch) {
		try {
			const { status = 0 } = await PUT(postConfig);
			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: { 0: { message: 'Record successfully updated', key: '' } },
				})
			);
			dispatch(updateList({ requestType: 'edit', id }));
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			dispatch(errorHandling(error));
		}
	};
}

export function deleteUser(id = 0) {
	const delConfig = { url: `clinics/${id}` };
	return async function (dispatch) {
		try {
			const { status = 0 } = await DELETE(delConfig);
			dispatch(getListAction());
			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: {
						0: { message: 'Clinic successfully deleted', key: '' },
					},
				})
			);
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			dispatch(errorHandling(error));
		}
	};
}

function clinicManagementReducer(state = initialState, action) {
	switch (action.type) {
		case GET_STATUS: {
			return {
				...state,
				statusCode: action.status,
			};
		}
		case STORE_KEYWORDS:
			return {
				...state,
				keywords: action.searchKeyword,
			};

		case BEFORE_GET_LIST:
			return {
				...state,
				dataLoader: action.dataLoader,
			};
		case UPDATE_LIST: {
			const { data = {}, requestType = '', id = '' } = action;
			const { list = [] } = state;
			if (Object.keys(data).length !== 0 && id !== '' && requestType !== '') {
				list.forEach((d, i) => {
					if (d.id === id) {
						list[i] = data;
						return true;
					}
				});
			}

			return { ...state };
		}
		case GET_LIST: {
			console.log('DataLoader', action, action.dataLoader);
			return {
				...state,
				list: action.data,
				initialList: action.initialList,
				dataLoader: action.dataLoader,
				fetching: false,
				listOffset: 0,
				noOfResults: 1,
				totalResults: action.totalResults,
				/* statusCode: 0, */
			};
		}
		case GET_LIST_PAGINATION: {
			const { data } = action;
			return {
				...state,
				list: [...state.list, ...data],
				initialList: action.initialList,
				dataLoader: action.dataLoader,
				fetching: true,
				listOffset: action.offset,
				noOfResults: action.noOfResults,
				totalResults: action.totalResults,
			};
		}
		case GET_DETAILS:
			return {
				...state,
				details: action.data,
				detailsFetching: action.detailsFetching,
			};
		case BEFORE_GET_PATIENT_LIST:
			return {
				...state,
				patientFetching: action.patientFetching,
			};
		case GET_PATIENT_LIST:
			return {
				...state,
				patientList: action.data,
				patientFetching: action.patientFetching,
			};
		case CLEAR_STATUS:
			return {
				...state,
				statusCode: 0,
			};
		case CLEAR_ALL:
			return {
				...state,
				initialList: true,
				fetching: false,
				dataLoader: true,
				detailsFetching: false,
				list: [],
				patientList: [],
				details: {},
				listOffset: 0,
				keywords: '',
				params: '',
				statusCode: 0,
				noOfResults: 0,
				totalResults: 0,
			};

		default:
			return state;
	}
}

export default clinicManagementReducer;
