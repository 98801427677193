import styled from 'styled-components';
import { colors } from '../colors';
import RadioButton from '../radioButton/radioButton';
import { Row, Col } from '../..';

import DietPlanBG from '../../assets/images/DietPlan-bg.png';
import { fontBold, fontLight, fontMedium } from '../../assets/styles/typography';

const Panel = styled.div`
	display: inline-block;
	position: relative;
	box-sizing: border-box;
	width: 100%;
	margin-bottom: ${(props) => props.marginBottom}!important;
	margin-right: ${(props) => props.marginRight}!important;
	margin-top: ${(props) => props.marginTop}!important;
	text-align: ${(props) => props.textRight && 'right'}!important;
	text-align: ${(props) => props.text_left && 'left'}!important;
	padding: ${(props) => props.padding}!important;
	padding-bottom: ${(props) => props.paddingBottom}!important;
	padding-top: ${(props) => props.paddingTop}!important;
	height: ${(props) => props.height}!important;
	min-height: ${(props) => props.MinHeight}!important;
	width: ${(props) => props.width}!important;
	margin: ${(props) => props.margin}!important;
	overflow: ${(props) => props.overflow};
	@media screen and (max-width: 1200px) {
		height: auto;
	}
	@media screen and (max-width: 767px) {
		height: auto;
		min-height: auto;
	}
	${({ HoverShadow }) =>
		HoverShadow &&
		`
		&:hover{
			box-shadow: 0px 2px 21px rgba(69, 102, 189, 0.35);
			border: 1px solid #2C4DA2;
		}
	`}
	${({ IconLink }) =>
		IconLink &&
		`
		color: ${colors.BlueBase};
		font-family:  ${fontMedium.familyName};
		display: flex;
		align-items: center;
		span{
			padding-right:15px;
			color: ${colors.BlueBase};
			font-size:20px;
			width: 40px;
		}
		`}
	${({ IconLinkSmall }) =>
		IconLinkSmall &&
		`
		span{
			font-size:18px;
		}
		`}
	${({ IconLinkLarge }) =>
		IconLinkLarge &&
		`
		span{
			font-size:22px;
		}
		`}
	${({ chatLeft }) =>
		chatLeft &&
		`width: 410px;
		min-width: 410px;
		height: calc(100vh - 62px);
		display: flex;
		background: #fdfdfd;
		border-right: 1px solid ${colors.Border2};
		flex-direction: column;
		@media screen and (max-width: 991px) {
			min-width: 320px;
			width: 320px
		}
		@media screen and (max-width: 767px) {
			min-width: 100%;
			width: 100%;
		}
		`}
	${({ chatListWrapper }) =>
		chatListWrapper &&
		`padding-bottom:70px;
		overflow:auto;
		scrollbar-color: ${colors.GreyC4};
		scrollbar-width: thin;
		`}
	${({ FlexPanel }) =>
		FlexPanel &&
		`display: flex;
		`}

	${({ chatListWrapperSmall }) =>
		chatListWrapperSmall &&
		`overflow:auto;
		scrollbar-color: ${colors.GreyC4};
		scrollbar-width: thin;
		max-height:50vh; `}
	${({ chatRight }) =>
		chatRight &&
		`width: 100%;
		height: calc(100vh - 62px);
		display: flex;
		flex-direction: column;
		background: ${colors.White};
		@media screen and (max-width: 767px) {
			position: absolute;
			z-index: 8;
			left: 0;
			width: 100%;
		}
	`};
	${({ whiteBG }) =>
		whiteBG &&
		`background: ${colors.White};
	`};
	${({ chartPanel }) =>
		chartPanel &&
		`display: flex;
		justify-content: center;
		align-items: center;
	`};
	${({ NodataBlock }) =>
		NodataBlock &&
		`display: flex;
		justify-content: center;
		align-items: center;
		min-height:55vh;
		flex-direction: column;
		h3{
			color: ${colors.BlueBase};
			font-size: 30px;
			line-height: 30px;
			font-family: ${fontLight.familyName};
			margin:20px 0;
		}
		img{
			max-width:90%;
		}
		button{
			width: auto!important;
			color: ${colors.White};
			font-size:14px;
		}
	`};

	${({ invitationCode }) =>
		invitationCode &&
		`background: #E7F5FF;
		border: 1.5px dashed #A0BFD1;
		border-radius: 30px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #50586F;
	`};

	${({ profileBlock }) =>
		profileBlock &&
		`border: 1px solid ${colors.Border2};
		background: ${colors.White};
		border-radius: 14px;
		width: 450px;
		margin: 12px auto 0;
		position: relative;
		padding:100px 45px 30px 45px;
		height:100%;
        max-width: 100%;
		@media screen and (max-width: 1200px) {
			height: 100%;
		}
		@media screen and (max-width: 767px){
            padding:100px 15px 30px 15px;
            width: auto;
		}
	`};
	${({ dietPlanBlock }) =>
		dietPlanBlock &&
		`border: 1px solid ${colors.Border2};
		background: ${colors.White};
		border-radius: 14px;
		width: 590px;
		max-width: 100%;
		margin: 0 auto;
		position: relative;
		padding:30px 24px 30px 24px;
		height:100%;
		@media screen and (max-width: 1200px) {
			height: 100%;
		}
		min-height: calc(100vh - 190px);
		a{
			font-size: 16px;
			line-height: 22px;
			font-family:  ${fontMedium.familyName};
			color: ${colors.BlueLight};
			text-decoration: none;
			transition: all ease 0.4s;
			white-space: nowrap;
			margin-bottom:0px;
			:hover{
				color: ${colors.BlueBase};
				cursor: pointer;
			}
		}
	`};
	${({ dietPlanBlockSmall }) =>
		dietPlanBlockSmall &&
		`width: 430px;
		min-height: calc(100vh - 190px);
	`};
	${({ dietPlanBlockAuto }) =>
		dietPlanBlockAuto &&
		`height: auto!important;
		min-height: auto!important;
	`};
	${({ BorderPanel }) =>
		BorderPanel &&
		`border: 1px solid ${colors.Border2};
		background: ${colors.White};
		border-radius: 14px;
		margin: 0 auto;
		position: relative;
		padding:24px;
	`};
	${({ ReadOnlyPanel }) =>
		ReadOnlyPanel &&
		`background: #F9F9FA;
	`};
	${({ ReportsInfo }) =>
		ReportsInfo &&
		`+ div {
			border-top: 1px solid ${colors.Border2};
			padding-top: 15px;
			margin-top: 15px;
		}
	`};
	${({ NotesRepeatPanel }) =>
		NotesRepeatPanel &&
		`+ div {
			border-top: 1px solid ${colors.Border2};
			padding-top: 20px;
			margin-top: 10px;
		}
	`};

	${({ dietListWrapper }) =>
		dietListWrapper &&
		`max-height: calc(100vh - 345px);
		overflow: auto;
	`};
	${({ dietListWrapperSM }) =>
		dietListWrapperSM &&
		`max-height: calc(100vh - 400px);
		min-height: calc(100vh - 400px);
		overflow: auto;
	`};
	${({ dietListWrapperCareProvider }) =>
		dietListWrapperCareProvider &&
		`max-height: calc(100vh - 300px);
		min-height: calc(100vh - 300px);
		overflow: auto;
	`};
	${({ dietListWrapperNBT }) =>
		dietListWrapperNBT &&
		`> div{
			border-top: 0px!important;
		}
	`};
	${({ dietListWrapperXS }) =>
		dietListWrapperXS &&
		`max-height: calc(100vh - 280px);
		overflow: auto;
	`};
	${({ dietPlanIcon }) =>
		dietPlanIcon &&
		`width: 52px;
		height: 52px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: url(${DietPlanBG}) no-repeat;
		background-size: contain;
		span{
			color: ${colors.BlueBase};
			font-size: 48px;
		}
	`};
	${({ dashboardBlock }) =>
		dashboardBlock &&
		`background: ${colors.White};
		border-radius: 4px;
		position: relative;
		padding:24px;
		box-shadow: 0px 2px 6px rgba(15, 37, 95, 0.1);
		@media screen and (max-width: 600px){
			padding:20px 15px!important;
		}
		a{
			font-size: 16px;
			line-height: 22px;
			font-family:  ${fontMedium.familyName};
			color: ${colors.BlueLight};
			text-decoration: none;
			transition: all ease 0.4s;
			white-space: nowrap;
			:hover{
				color: ${colors.BlueBase};
				cursor: pointer;
			}
		}
	`};
	${({ BGWithShadow }) =>
		BGWithShadow &&
		`background: ${colors.White};
		border-radius: 4px;
		position: relative;
		padding:16px;
		box-shadow: 0px 2px 6px rgba(15, 37, 95, 0.1);
		transition: ease-in all 0.4s;
		border: 1px solid #eee;
		cursor: pointer;
    `};

	${({ titleTh }) =>
		titleTh &&
		`background: #eef0f4;
		border-radius: 4px;
		position: relative;
		padding:16px;
		color: ${colors.Black};
		transition: ease-in all 0.4s;
        text-align: left;
        font-family:  ${fontBold.familyName};
        font-size: 15px;
        line-height: 31px;
        /* border-bottom: 1px solid #cdd3e0; */
	`};

	${({ HealthBlocksPanel }) =>
		HealthBlocksPanel &&
		`@media screen and (max-width: 991px){
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			padding-bottom:24px;
		}
	`};

	${({ HealthBlocks }) =>
		HealthBlocks &&
		`max-width: 167px;
		margin-right: 16px;
		margin-bottom: 16px;
	min-height: 143px;
		&:hover{
			box-shadow: 0px 2px 21px rgba(69, 102, 189, 0.35);
			border: 1px solid #2C4DA2;
		}
		@media screen and (max-width: 500px) {
			max-width: 100%;
			margin-right:0px;
		}
	`};
	${({ HealthBlocksXLG }) =>
		HealthBlocksXLG &&
		`max-width: 442px;
		min-width: 442px;
		margin-right: 16px!important;
		margin-bottom: 16px;
		&:hover{
			box-shadow: 0px 2px 21px rgba(69, 102, 189, 0.35);
			border: 1px solid #2C4DA2;
		}
		@media screen and (max-width: 1100px) {
			max-width: calc(50% - 16px);
			min-width: calc(50% - 16px);
		}

		@media screen and (max-width: 767px) {
			${Row}{
				flex-direction: column;
				align-items: flex-start;
				${Col}{
					justify-content: flex-start;
					margin-top:5px;
				}
			}
		}

	`};
	${({ HealthBlocksLink }) =>
		HealthBlocksLink &&
		`padding: 0px;
		a{
			padding: 16px;
			text-decoration: none;
			display: inline-block;
			width: 100%;
			&:hover{
				color: ${colors.BlueBase};
			}
		}
	`};

	${({ HealthBlocksLG }) =>
		HealthBlocksLG &&
		`max-width: 190px;
		@media screen and (max-width: 500px) {
			max-width: 100%;
			margin-right:0px;
		}
	`};
	${({ HealthBlocksXLG }) =>
		HealthBlocksXLG &&
		`max-width: 270px;
		margin-right:0px;
		@media screen and (max-width: 500px) {
			max-width: 100%;
			margin-right:0px;
		}
	`};
	${({ HealthBlocksFull }) =>
		HealthBlocksFull &&
		`max-width: calc(100% - 20px);
		@media screen and (max-width: 991px) {
			max-width: 100%;
			margin-right:0px;
		}
	`};
	${({ TemplateBox }) =>
		TemplateBox &&
		`max-width: 197px;
		min-width: 197px;
		margin:20px 20px 20px 0;
		padding:15px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		min-height:196px!important;
		${RadioButton}{
			position: absolute;
			right: 15px;
			top: 15px;
        }
        img{
           border-radius:16px;
        }
	`};

	${({ BottomBlock }) =>
		BottomBlock &&
		`min-height: calc(100vh - 580px);
		@media screen and (max-width: 1200px) {
			min-height: auto;
		}
	`};
	${({ DateSeperator }) =>
		DateSeperator &&
		`position: relative;
		height:30px;
		padding-top: 8px;
		&:after{
			position: absolute;
			left: 0px;
			top: 16px;
			border-top:1px solid ${colors.Border2};
			content: '';
			width: 100%;
			height: 1px;
		}
		span{
			position: relative;
			z-index: 9;
			padding-right: 15px;
			background: ${colors.White};
			color: #334677;
		}
	`};
	${({ DateSeperatorPadLeft }) =>
		DateSeperatorPadLeft &&
		`span{
			padding-Left: 15px!important;
		}
	`};
	${({ DateSeperatorRBSLeft }) =>
		DateSeperatorRBSLeft &&
		`span{
			padding-Left: 0px!important;
		}
	`};
	${({ clinicAdmin }) =>
		clinicAdmin &&
		`min-height: calc(100vh - 168px);
		@media screen and (max-width: 991px) {
			min-height: auto;
		}
	`};
	${({ minHeight }) =>
		minHeight &&
		`min-height: calc(100vh - 110px);
		@media screen and (max-width: 991px) {
			min-height: auto;
		}
	`};
	${({ Information }) =>
		Information &&
		`background: #F9F9FA;
		border-radius: 6px;
		text-align: center;
		color: ${colors.BlueBase};
		padding:12px 5px;
		margin-top: 20px;
		line-height: 22px;
		span{
			font-family:  ${fontMedium.familyName};
		}
`};
	${({ leftBorderColor }) =>
		leftBorderColor && `border-left: 5px solid ${leftBorderColor} !important;`};
	${({ withShadow }) =>
		withShadow &&
		`border: 1px solid #DEE3F0; box-shadow: 0px 0px 8px rgba(83, 109, 254, 0.15); border-radius: 8px;`};
	${({ ReportCardDetails }) =>
		ReportCardDetails &&
		`+ ${Panel}{
				border-top: 1px solid ${colors.Border2};
				padding-top:10px;
				margin-top: 10px;
			}
		`}
	${({ Attachments }) =>
		Attachments &&
		`background: rgba(126, 157, 240, 0.09);
		border: 1px dashed #6C7BA4;
		border-radius: 4px;
		height: 320px!important;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: relative;
		span{
			color: #7683A7;
			font-size: 30px;
		}
		h3{
			color:  ${colors.BlueBase};
			font-size: 13px;
			line-height: 15px;
			margin:30px 0 10px;
		}
		p{
			color: ${colors.Grey7};
			font-size: 12px;
			line-height: 14px;
		}
		input[type="file"]{
			width: 100%;
			height: 390px;
			position: absolute;
			left:0;
			top: 0;
			opacity:0;
        }
		`}
	${({ AttachmentsAdded }) =>
		AttachmentsAdded &&
		`background: rgba(126, 157, 240, 0.09);
		border: 1px dashed rgba(126, 157, 240, 0.09);
		border-radius: 4px;
		position: relative;
		padding: 14px;
		`}
	${({ TimelineImage }) =>
		TimelineImage &&
		`position: absolute;
		left: -62px;
		top: calc(50% - 20px);
		width:40px;
		height:40px;
		`}
	${({ ScrollPanel }) =>
		ScrollPanel &&
		`overflow: auto;
		max-height:600px;
		padding-right: 5px;
        `}
	${({ ScrollPanelHeight }) =>
		ScrollPanelHeight &&
		`max-height:500px !important;
		`}
	${({ LineSeperator }) =>
		LineSeperator &&
		`width:100%;
		height:1px;
		background: ${colors.Border2};
		margin:15px 0;
        `}
    ${({ bottomBtn }) => bottomBtn && `bottom:0; position: absolute;`};

	${({ hideChat }) =>
		hideChat &&
		`
	@media screen and (max-width: 767px){display: none!important;}`};
	${({ PatientSearchHolder }) =>
		PatientSearchHolder &&
		`display: flex;
		@media screen and (max-width: 1030px) {
			margin-top:20px;
		}
		@media screen and (max-width: 640px) {
			width:100%;
			flex-wrap:wrap;
		}
		select,section{
			height: 32px;
			padding: 0px;
			border-radius: 6px;
		}
		section{
			width:400px;
			@media screen and (max-width: 900px) {
				width:300px;
			}
			@media screen and (max-width:640px) {
				width:calc(100% - 132px);
			}
			@media screen and (max-width:480px) {
				width:calc(100% - 55px);
				+ button{
					div{
						display:none;
					}
					span{
						padding-right: 0px;
					}
				}
			}
			
			div{
				width:100%;
				margin:0;
				div{
					width:auto
				}
			}
		}
		select{
			width:200px;
			margin-right: 10px;
			@media screen and (max-width: 640px) {
				width:100%;
				margin-right: 0;
				margin-bottom:20px;
			}
		}
`}
	${({ ClientSearchHolder }) =>
		ClientSearchHolder &&
		`display: flex;
		justify-content: flex-end;
		@media screen and (max-width: 1030px) {
			margin-top:20px;
		}
		@media screen and (max-width: 640px) {
			width:100%;
			flex-wrap:wrap;
		}
		select,section{
			height: 32px;
			padding: 0px;
			border-radius: 6px;
		}
		section{
			width:400px;
			@media screen and (max-width: 900px) {
				width:300px;
			}
			@media screen and (max-width:640px) {
				width:calc(100% - 132px);
			}
			@media screen and (max-width:480px) {
				width:calc(100% - 55px);
				+ button{
					div{
						display:none;
					}
					span{
						padding-right: 0px;
					}
				}
			}
			
			div{
				width:100%;
				margin:0;
				div{
					width:auto
				}
			}
		}
		select{
			width:200px;
			margin-right: 10px;
			@media screen and (max-width: 640px) {
				width:100%;
				margin-right: 0;
				margin-bottom:20px;
			}
		}
`}
`;

export default Panel;
