import styled from 'styled-components';
import { colors } from '../colors';

const ModalInnerWrapper = styled.div`
	transition: ease-in all 0.2s;
	box-sizing: border-box;
	width: 500px;
	background: ${colors.White};
	border-radius: 14px;
	display: flex;
	flex-direction: column;
	@media screen and (max-width: 500px) {
		width: 90% !important;
	}
	${({ ModalLG }) =>
		ModalLG &&
		`width:950px;
		@media screen and (max-width: 991px) {
			width: 90%;
		}
	`};
	${({ ModalSM }) =>
		ModalSM &&
		`width:350px;
		@media screen and (max-width: 991px) {
			width: 90%;
		}
    `};
	${({ box }) =>
		box &&
		`width:350px;
		@media screen and (max-width: 480px) {
			width: 90%;
		}
    `};
	${({ modalContainer }) =>
		modalContainer &&
		`width:550px;
		@media screen and (max-width: 592px) {
			width: 90%;
		}
    `};
	${({ attachmentModel }) =>
		attachmentModel &&
		`width:765px;
		height:510px;
		background: ${colors.White};
		color: ${colors.White};
		border-radius: 6px;
		img{
			width: 765px;
			height: 510px;
			object-fit: scale-down;
		}
		@media screen and (max-width: 992px) {
			width: 70%;
			height: auto;
			img{
				width: 100%;
				height: auto;
				object-fit: scale-down;
			}
		}
		@media screen and (max-width: 767px) {
			width:55%;
		}
	`}
	${({ autoWidth }) =>
		autoWidth &&
		`width:550px;
		@media screen and (max-width: 480px) {
			width: 90%!important;
		}
	`}
	${({ healthProfile }) =>
		healthProfile &&
		`width:960px;
		@media screen and (max-width: 1024px) {
			width: 90%;
		}
	`}
`;

export default ModalInnerWrapper;
