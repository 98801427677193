/**
 * Library used for handling HTTP request : axios
 * GIT: https://github.com/axios/axios
 * NPM: https://www.npmjs.com/package/axios
 */

import Axios from 'axios';

import envConfig from '../config/environment';

import { APP_TOKEN, REFRESH_TOKEN } from '../helpers/Constants';

const axiosConfig = {
	baseURL: envConfig.baseURL(),
	headers: {
		'Content-Type': 'Application/json',
		'x-user-type': 'provider',
	},
};

const getAccessToken = () => localStorage.getItem(APP_TOKEN);
const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);

function getApiConfig({ headers = {}, appConfig = {} }) {
	const mainConfig = {
		...axiosConfig,
		headers: {
			...axiosConfig.headers,
			...headers,
			Authorization: getAccessToken(),
		},
	};

	if (appConfig.baseURL === '') mainConfig.baseURL = appConfig.baseURL;
	if (appConfig.doNotNeedAuthorizationHeader) delete mainConfig.headers.Authorization;

	return mainConfig;
}

const ApiCall = (ajaxParams) => Axios(ajaxParams);

export function delay(time, value) {
	return new Promise((resolve) => {
		setTimeout(resolve.bind(null, value), time);
	});
}

export const FILE_POST = ({
	url = '',
	params = {},
	data = {},
	headers = {},
	appConfig = {},
	onUploadProgress = null,
}) => {
	if (!url) throw new Error('Please specify a API URL');

	const config = getApiConfig({ headers, appConfig });

	const ajaxParams = {
		...config,
		url,
		data,
		params,
		method: 'POST',
		onUploadProgress,
	};

	return Axios(ajaxParams);
};

export const GET = ({ url = '', params = {}, headers = {} }) => {
	if (!url) throw new Error('Please specify a API URL');

	const config = getApiConfig({ headers });

	const ajaxParams = {
		...config,
		url,
		params,
		method: 'GET',
	};

	return ApiCall(ajaxParams);
};

export const POST = ({ url = '', params = {}, data = {}, headers = {}, appConfig = {} }) => {
	if (!url) throw new Error('Please specify a API URL');

	const config = getApiConfig({ headers, appConfig });

	const ajaxParams = {
		...config,
		url,
		data,
		params,
		method: 'POST',
	};

	return ApiCall(ajaxParams);
};

export const PUT = ({ url = '', params = {}, data = {}, headers = {} }) => {
	if (!url) throw new Error('Please specify a API URL');

	const config = getApiConfig({ headers });

	const ajaxParams = {
		...config,
		url,
		data,
		params,
		method: 'PUT',
	};

	return ApiCall(ajaxParams);
};

export const PATCH = ({ url = '', params = {}, data = {}, headers = {} }) => {
	if (!url) throw new Error('Please specify a API URL');

	const config = getApiConfig({ headers });

	const ajaxParams = {
		...config,
		url,
		data,
		params,
		method: 'PATCH',
	};

	return ApiCall(ajaxParams);
};

export const DELETE = ({ url = '', params = {}, data = {}, headers = {}, appConfig = {} }) => {
	if (!url) throw new Error('Please specify a API URL');

	const config = getApiConfig({ headers, appConfig });

	const ajaxParams = {
		...config,
		url,
		data,
		params,
		method: 'DELETE',
	};

	return ApiCall(ajaxParams);
};

let source;
export const SEARCH = ({ url = '', params = {}, headers = {} }) => {
	if (!url) throw new Error('Please specify a API URL');

	const config = getApiConfig({ headers });
	if (source) {
		source.cancel();
	}
	const { CancelToken } = Axios;
	source = CancelToken.source();

	const ajaxParams = {
		...config,
		url,
		params,
		method: 'GET',
		cancelToken: source.token,
	};

	return ApiCall(ajaxParams);
};

function responseInterceptorFunction() {
	const responseInterceptor = Axios.interceptors.response.use(
		(response) =>
			/**
			 * Any status code that lie within the range of 2xx cause this function to trigger
			 * Do something with response data
			 */
			response,
		(error) => {
			const {
				config: originalRequest,
				response: { status },
			} = error;

			if (status === 401) {
				/*
				 * When response code is 401, refresh the app-token using existing refresh-token.
				 * Eject the interceptor so it doesn't loop in case of 401 again...
				 * token refresh causes the 401 response
				 */
				Axios.interceptors.response.eject(responseInterceptor);

				return (
					POST({
						url: 'accounts/refresh',
						data: { token: getRefreshToken() },
					})
						.then((response) => {
							const { data: { accessToken } = {} } = response;
							localStorage.setItem(APP_TOKEN, accessToken);
							originalRequest.headers.Authorization = `${accessToken}`;
							return Axios(originalRequest);
						})
						// .catch(error => {
						// 	// remove locally saved tokens
						// 	// localStorage.clear();
						// 	// dispatch logout functionality
						// 	console.log(' $$$ response error %%% $$$ ', error.response);

						// 	// return new Error({ error, message: 'LOGOUT_MESSAGE' });
						// 	return Promise.reject(error);
						// })
						.finally(responseInterceptorFunction)
				);
			}

			/**
			 * Any status codes that falls outside the range of 2xx cause this function to trigger
			 * Do something with response error
			 */

			return Promise.reject(error);
		}
	);
}

responseInterceptorFunction();
