import styled from 'styled-components';
import { fontBold, fontMedium, fontRegular } from '../../assets/styles/typography';
import { colors } from '../colors';

const DropdownList = styled.div`
	transition: ease-in all 0.4s;
	padding: 10px 20px;
	display: flex;
	border: 1px solid transparent;
	width: 100%;
	color: ${colors.BlueBase};
	font-size: 14px;
	font-family: ${fontRegular.familyName};
	position: relative;
	:hover {
		background: ${colors.GreyEC};
		cursor: pointer;
	}
	a {
		text-decoration: none;
	}
	img {
		padding-right: 10px;
	}
	span {
		font-size: 16px;
		margin-right: 10px;
	}
	input[type='file'] {
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		cursor: pointer;
		z-index: 9;
	}
	div {
		display: flex;
		align-items: center;
	}
	a {
		display: flex;
		align-items: center;
	}
	${({ Applied }) =>
		Applied &&
		`font-family: ${fontBold.familyName};
	`}
	${({ countryCodeDropdownList }) =>
		countryCodeDropdownList &&
		`font-family: ${fontMedium.familyName};
		padding: 10px 10px;
		div{
			font-family: ${fontMedium.familyName};
		}
	`}
	${({ noHover }) =>
		noHover &&
		`:hover {
		background: ${colors.White};
		cursor: default;
	}
	`}
`;

export default DropdownList;
