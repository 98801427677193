import styled from 'styled-components';
import { fontBold } from '../../assets/styles/typography';
import { colors } from '../colors';

const Badge = styled.span`
	transition: all ease 0.4s;
	background: ${colors.BlueLight};
	color: ${colors.White};
	font-size: 13px;
	padding: 12px;
	min-width: 18px;
	height: 18px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	line-height: 12px;
	${({ rounded }) =>
		rounded &&
		`border-radius:20px;
        padding:4px 6px;
        `}

	${({ Grey }) => Grey && `border-radius:20px; background: ${colors.Grey87}; `}
	${({ Pending }) => Pending && `min-width: 82px;border-radius:20px; background: ${colors.Red1}; `}
    ${({ Overweight }) =>
		Overweight &&
		`min-width: 82px;border-radius:20px;  font-family: ${fontBold.familyName}; margin-bottom:10px; background: ${colors.Red2}; color: ${colors.White}; `}
    ${({ UnderWeight }) =>
		UnderWeight &&
		`min-width: 82px;border-radius:20px;  font-family: ${fontBold.familyName}; margin-bottom:10px; background: #4B4FD; color: ${colors.White}; `}
    ${({ HealthyWeight }) =>
		HealthyWeight &&
		`min-width: 82px;border-radius:20px;  font-family: ${fontBold.familyName}; margin-bottom:10px; background: #5CDBAD; color: ${colors.White};
        @media screen and (max-width: 600px) {
			text-align: center;
	height: auto;
	padding: 3px;
	font-size: 12px;
		}
        `}
     ${({ Obese }) =>
		Obese &&
		`min-width: 82px;border-radius:20px;  font-family: ${fontBold.familyName}; margin-bottom:10px; background: #FF7777; color: ${colors.White}; `}
	${({ Confirmed }) =>
		Confirmed && `min-width: 82px;border-radius:20px; background: ${colors.GreenBase}; `}
    ${({ Easy }) => Easy && `min-width: 82px;border-radius:20px; background: ${colors.Green2}; `}
    ${({ Hard }) => Hard && `min-width: 82px;border-radius:20px; background: ${colors.Red3}; `}
    ${({ Moderate }) =>
		Moderate && `min-width: 82px;border-radius:20px; background: ${colors.Red4}; `}
`;
export default Badge;
