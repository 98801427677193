import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import DropdownWrapper from './dropdownWrapper';
import DropdownMenu from './dropdownMenu';
import { colors } from '../colors';

const DropdownToggle = styled.button`
	border: 0px;
	background: transparent;
	padding: 8px;
	border-radius: 4px;
	color: ${colors.Grey69};

	:hover,
	:focus {
		background: ${colors.GreyEC};
	}
	span {
		font-size: 16px;
	}
	${({ large }) =>
		large &&
		`width:48px;
		height:48px;
    `}
	${({ noHover }) =>
		noHover &&
		`:hover,
	:focus {
		background: ${colors.White};
	}
    padding-top:11px !important;
    `}
	${({ disable }) => disable && `pointer-events: none;`}
`;

const Dropdown = (props) => {
	const wrapperRef = useRef(null);
	const {
		open = false,
		noHover = false,
		children,
		reverse = false,
		disable = false,
		...attrs
	} = props;
	let { name } = props;
	const [isOpen = false, setIsOpen] = useState(open);
	const toggleOpen = () => setIsOpen(!isOpen);

	if (reverse && isOpen) {
		name = reverse;
	}

	const handleClickOutside = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, false);
		return () => document.removeEventListener('click', handleClickOutside, false);
	}, []);

	return (
		<DropdownWrapper ref={wrapperRef}>
			<DropdownToggle noHover={noHover} onClick={toggleOpen} primary disable={disable}>
				{name}
			</DropdownToggle>
			<DropdownMenu onClick={toggleOpen} open={isOpen} {...attrs}>
				{children}
			</DropdownMenu>
		</DropdownWrapper>
	);
};

export default Dropdown;
