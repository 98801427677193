import styled from 'styled-components';

// import Image01 from '../../assets/images/WeCareLogo-Sidebar.png';

const SidebarLogo = styled.div`
    width: 81px;
    height: 18px;
    display: flex;
    align-items: center;
    background-size: cover;
    margin-left: 24px;
}
`;

export default SidebarLogo;
