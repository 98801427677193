/**
 * Any one of the values should be present in the .env file
 * [REACT_APP_PROJECT_ENV=dev | qa | uat | prod]
 * If not present, dev instance will be used
 */
export const instances = {
	dev: 'dev',
	qa: 'qa',
	staging: 'uat',
	prod: 'production',
};

const config = {
	[instances.dev]: {
		API_ENDPOINT: 'https://wecare-api-dev.joinwecare.dev/api/v1/',
		WEB_ENDPOINT: 'https://wecare-dev.joinwecare.dev/api/v1/',
	},
	[instances.qa]: {
		WEB_ENDPOINT: 'https://wecare-qa.joinwecare.dev/api/v1/',
		API_ENDPOINT: 'https://wecare-api-dev.joinwecare.dev/api/v1/',
	},
	[instances.staging]: {
		WEB_ENDPOINT: 'https://wecare-uat.joinwecare.dev/api/v1/',
		API_ENDPOINT: 'https://wecare-api-dev.joinwecare.dev/api/v1/',
	},
	[instances.prod]: {
		WEB_ENDPOINT: 'https://wecare-uat.joinwecare.dev/api/v1/',
		API_ENDPOINT: 'https://wecare-api-dev.joinwecare.dev/api/v1/',
	},
};

export default {
	currentInstance: process.env.REACT_APP_PROJECT_ENV || instances.dev,
	sentryDSN: process.env.REACT_APP_SENTRY_DSN || '',
	latestAppVersion: process.env.REACT_APP_LATEST_RELEASE_VERSION || '',
	baseURL() {
		return config[this.currentInstance].API_ENDPOINT;
	},
};
