import { Roles } from '../../helpers/Constants';

export default [
	{
		to: '/patient-management',
		icon: 'icon-patient-management',
		label: 'Patient management',
		visibleTo: [Roles.clinicAdmin, Roles.weCareAdmin],
	},

	{
		to: '/clinic-management',
		icon: 'icon-clinic-management',
		label: 'Clinic administration',
		visibleTo: [Roles.weCareAdmin],
	},
];
