// Blue
export const BlueBase = '#0F255F';
export const BlueDark = '#0b1d4c';
export const BlueDarkX = '#07163E';
export const BlueLight = '#536DFE';
export const BlueLight1 = '#4CAFFF';
export const BlueLightDark = '#334fe8';

// Violet
export const Violet = '#6A4EF6';

// White
export const White = '#ffffff';

// Black
export const Black = '#000000';
export const Grey = '#dddddd';
export const Grey8c = '#8C8C8C';
export const Grey8 = '#888888';
export const Grey7 = '#777777';
export const Grey3 = '#333333';
export const GreyA2 = '#A2ABC1';
export const GreyCE = '#cecece';
export const GreyC4 = '#C4C4C4';
export const GreyEC = '#ececec';
export const Grey6 = '#666666';
export const Grey69 = '#696969';
export const Grey87 = '#8792af';

// Grey
export const Grey100 = '#f5f5f5';
export const Grey400 = '#4898ED';

// Red
export const Red = '#CD0000';
export const Red1 = '#EF6868';
export const Red2 = '#F59C16';
export const Red3 = '#FF7777';
export const Red4 = '#E0B136';

// Yellow
export const Yellow = '#ff8100';
export const YellowDark = '#FFB059';

// Green
export const GreenBase = '#28a745';
export const GreenDark = '#218838';
export const Green1 = '#60CC17';
export const Green2 = '#7FC753';

// Textbox
export const BorderColorTB = '#C9CED8';
export const ShadowColorTB = '#cad9ff';

// Scrollbar
export const ScrollbarColor = '#C4C4C4';

// Placeholder
export const PlaceholderColor = '#B5B5B5';

// Chat
export const IncomingChatbg = '#5d73bc';
export const OutgoingChatbg = '#eef1f8';

// Border
export const Border1 = '#8ba3e2';
export const Border2 = '#e5e7e9';

export const colors = {
	BlueBase,
	BlueDark,
	BlueDarkX,
	BlueLight,
	BlueLight1,
	BlueLightDark,
	White,
	Red,
	Red1,
	Red2,
	Red3,
	Red4,
	Yellow,
	YellowDark,
	Violet,
	Black,
	Grey,
	Grey100,
	Grey400,
	Grey8c,
	Grey8,
	Grey3,
	Grey7,
	GreyA2,
	Grey6,
	Grey69,
	Grey87,
	GreyEC,
	GreyCE,
	GreyC4,
	BorderColorTB,
	ShadowColorTB,
	Border1,
	Border2,
	GreenBase,
	GreenDark,
	Green1,
	Green2,
	ScrollbarColor,
	IncomingChatbg,
	OutgoingChatbg,
};
