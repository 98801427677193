import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../colors';
import Icon from '../icons-font/icon';

const EnlargeStyle = styled.div`
	display: flex !important;
	span {
		display: none;
	}
	${({ open }) =>
		open &&
		`width: 100%;
	height: 100%;
    background: ${colors.Black};
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	align-items: center;
	justify-content: center;
	display: none;
	overflow: auto;
    transition: ease-n all 0.4s;

    img{ width:auto;}

	span {
        display:block !important;
		position: absolute;
		right: 10px;
		top: 10px;
		color: ${colors.White};
		cursor: pointer;
		padding: 8px;
		border-radius: 30px;
		background: ${colors.Black};
		font-size: 12px;
    }
    `}
`;

const Enlarge = (props) => {
	const { open = false, children } = props;
	const [isOpen = false, setIsOpen] = useState(open);
	const toggleOpen = () => setIsOpen(!isOpen);

	useEffect(() => {
		function handleEscape(event) {
			if (event.key === 'Escape') setIsOpen(false);
		}

		if (isOpen) document.addEventListener('keydown', handleEscape);

		if (!isOpen) document.removeEventListener('keydown', handleEscape);

		return () => document.removeEventListener('keydown', handleEscape);
	}, [isOpen]);

	return (
		<EnlargeStyle onClick={toggleOpen} open={isOpen}>
			<Icon iconName="icon-close" />
			{children}
		</EnlargeStyle>
	);
};

export default Enlarge;
