import styled from 'styled-components';

const PageHeader = styled.section`
	width: 100%;
	display: flex;
	align-items: center;
	padding: 24px;
	${({ chatHeader }) =>
		chatHeader &&
		`padding:24px 15px 15px 24px;
		@media screen and (max-width: 767px) {
			button{
				+ div{
					right:0;
				}
			}
		}
	`};
	${({ Responsive }) =>
		Responsive &&
		`@media screen and (max-width: 767px) {
		flex-direction: column;
		padding: 15px 24px;
	}
`};
	${({ PatientManagementHeader }) =>
		PatientManagementHeader &&
		`flex-wrap:wrap;
		justify-content: space-between;
		align-items:flex-start;
		div{
			width:auto;
			@media screen and (max-width: 767px) {
				width:100%;
			}

		}
	`};
`;

export default PageHeader;
