import styled from 'styled-components';
import { fontMedium } from '../../assets/styles/typography';

const TooltipHeading = styled.label`
	transition: all ease 0.4s;
	max-width: ${(props) => props.MaxWidth}!important;
	${({ TextEllipsis }) =>
		TextEllipsis &&
		`max-width: 260px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: inline-block;
		height: 15px;
		position: relative;
		top: 4px;
	`}
	${({ TextBold }) =>
		TextBold &&
		`font-family:${fontMedium.familyName}
	`}
`;
export default TooltipHeading;
