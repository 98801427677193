import styled from 'styled-components';
import { colors } from '../colors';

const ModalClose = styled.button`
	border: 0px;
	background: transparent;
	color: ${colors.Grey7};
	transition: ease all 0.4s;
	padding: 0px;
	&:hover,
	&:focus,
	&:active {
		background: transparent;
		color: ${colors.Grey3};
	}
	${({ modalCloseAttachment }) =>
		modalCloseAttachment &&
		`top: 10px;
		right: 5px;
		&:hover,
		&:focus,
		&:active {
			color: ${colors.White};
		}
	`};
`;

export default ModalClose;
