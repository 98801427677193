import styled from 'styled-components';
import { colors } from '../colors';

const cardsHeader = styled.div`
	display: inline-block;
	width: 100%;
	background: #e1e5ed;
	border-radius: 4px 4px 0 0;
	/* border-left: 2px solid ${colors.BlueBase}; */
	padding: 16px;
	padding: ${(props) => props.padding};
	${({ line }) =>
		line &&
		`background: #eeeeef;
        border-left: 0px;
    `};
`;

export default cardsHeader;
