import styled from 'styled-components';
import TopArrow from '../../assets/images/dropdowntop_arrow.png';

const DropdownWrapper = styled.div`
	position: relative;
	display: inline-block;
	a {
		text-decoration: none;
	}
	.weightTrackingInfo {
		width: 280px;
		right: -126px;
		&:after {
			content: '';
			width: 16px;
			height: 18px;
			background: url(${TopArrow}) no-repeat;
			position: absolute;
			top: -11px;
			left: 129px;
		}
	}
`;

export default DropdownWrapper;
