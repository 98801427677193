import styled from 'styled-components';
import { fontBold, fontMedium, fontRegular } from '../../assets/styles/typography';
import { colors } from '../colors';

const Heading = styled.h3`
	margin: 0px;

	${({ pageName }) =>
		pageName &&
		`font-size: 16px;
		line-height: 19px;
		color: ${colors.BlueBase};
		font-family: ${fontBold.familyName};
		@media screen and (max-width: 600px) {
			font-size: 16px;
		}
		span{
			font-size: 13px;
			line-height: 15px;
			text-align: right;
			color: #334677;
			padding-left: 5px;
		}
		`}
	${({ textSecondary }) =>
		textSecondary &&
		`color: ${colors.Grey6F};
	`}
	${({ cardHeading }) =>
		cardHeading &&
		`font-size: 16px;
		line-height: 19px;
		color: ${colors.BlueBase};
		font-family: ${fontBold.familyName};
		display: flex;
		align-items: center;
		span{
			color: #334677;
			font-size: 13px;
			line-height: 15px;
			padding-left: 15px;
			position: relative;
			&:before{
				content: '';
				position: absolute;
				left: 6px;
				top: 6px;
				width: 4px;
				height: 4px;
				border-radius:20px;
				background: #67769B;
			}
		}
		`}
	${({ HeadingMD }) =>
		HeadingMD &&
		`font-size: 18px;
		line-height: 23px;
		padding-bottom: 12px;
		color: ${colors.BlueBase};
		font-family: ${fontBold.familyName};
		`}
	${({ HeadingVLarge }) =>
		HeadingVLarge &&
		`font-size: 30px;
		line-height: 35px;
		padding-bottom: 12px;
		color: ${colors.BlueBase};
		font-family: ${fontBold.familyName};
		span{
			font-size: 14px!important;
			line-height: 19px;
			color: ${colors.BlueBase};
			font-family: ${fontBold.familyName};
		}
		`}

	${({ HeadingMDImage }) =>
		HeadingMDImage &&
		`font-size: 16px;
		line-height: 21px;
		padding-bottom: 12px;
		color: ${colors.BlueBase};
		font-family: ${fontBold.familyName};
		display: flex;
		align-items: center;
		white-space: nowrap;
		img{
			margin-right:4px;
		}
		span{
			font-size: 12px;
			position: relative;
			bottom: -2px;
		}
		sup{
			position: relative;
    		top: -7px;
		}
		`}
	${({ HeadingVitalBlock }) =>
		HeadingVitalBlock &&
		`font-size: 16px;
		line-height: 21px;
		padding-bottom: 12px;
		color: ${colors.BlueBase};
		font-family: ${fontBold.familyName};
		display: flex;
		align-items: center;
		img{
			margin-right:4px;
		}
		span{
			font-size: 12px;
			position: relative;
			bottom: -2px;
		}
		sup{
			position: relative;
    		top: -7px;
		}
		`}
	${({ HeadingNoFlex }) =>
		HeadingNoFlex &&
		`display: inline-block;
		span{
			font-size: 12px;
			position: static;
		}
		`}
	${({ Font16 }) => Font16 && `font-size: 16px; line-height: 19px; `};
	${({ Lowercase }) => Lowercase && `text-transform: lowercase;`};
	${({ Primary }) => Primary && `color: ${colors.BlueBase};`};
	${({ chatName }) =>
		chatName &&
		`font-size: 16px;
		line-height: 19px;
		color: ${colors.BlueBase};
		font-family: ${fontMedium.familyName};
		white-space:nowrap;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		div{
			font-family: ${fontMedium.familyName};
		}
		`};
	${({ HeadingSM }) =>
		HeadingSM &&
		`font-size: 16px;
		line-height: 19px;
		color: ${colors.BlueBase};
		font-family:  ${fontMedium.familyName};
		//white-space:nowrap;
		padding-right:10px;
		// max-width: 100%;
		// overflow: hidden;
		// text-overflow: ellipsis;
		div{
			font-family:  ${fontMedium.familyName};
		}
		span{
			font-family:  ${fontRegular.familyName};
			font-size: 14px;
		}
		img{
			min-width: 14px;
    		width: 14px;
		}
		`};
	${({ HeadingSMC }) =>
		HeadingSMC &&
		`font-size: 16px;
		line-height: 19px;
		color: ${colors.BlueBase};
		font-family:  ${fontMedium.familyName};
		padding-right:10px;
		display: flex;
		justify-content: flex-end;
		img{
			min-width: 14px;
			width: 14px;
			max-width: 14px;
			margin-right: 5px;
		}
		span{
			font-family:  ${fontRegular.familyName};
		}
		`};
	${({ HeadingXS }) =>
		HeadingXS &&
		`font-size: 14px;
		line-height: 16px;
		color: ${colors.BlueBase};
		font-family:  ${fontMedium.familyName};
		@media screen and (max-width: 767px) {
			margin-bottom: 10px;
		}
		`};
	${({ ReportsInfoHeading }) =>
		ReportsInfoHeading &&
		`font-size: 14px;
		line-height: 16px;
		color: ${colors.BlueBase};
		font-family:  ${fontMedium.familyName};
		`};

	${({ beginChat }) =>
		beginChat &&
		`margin-top:35px; color: ${colors.BlueBase};font-family:  ${fontBold.familyName}; font-size: 26px; line-height: 30px; text-align: center`};
	${({ ChartInfoHeading }) =>
		ChartInfoHeading &&
		`position: absolute;
		top: 110px;
		font-size: 32px;
		line-height: 37px;
		color:  ${colors.BlueBase};
		font-family:  ${fontBold.familyName};
		margin-left:-5px;
		`};
	${({ ChartDetailsHeading }) =>
		ChartDetailsHeading &&
		`font-size: 22px;
		line-height: 26px;
		color:  ${colors.BlueBase};
		font-family:  ${fontBold.familyName};
		position: relative;
		&:before{
			content: '';
			position: absolute;
			left: -30px;
    		top: 2px;
			width: 20px;
			height: 20px;
			border-radius:4px;

		}
		span{
			font-family:  ${fontRegular.familyName};
			color: #A2A8B9;
			font-size: 18px;
		}
		@media screen and (max-width: 600px) {
			font-size: 18px;
		}
		`};
	${({ Invitees }) =>
		Invitees &&
		`&:before{
			background: ${colors.YellowDark};
		}
	`};
	${({ Patients }) =>
		Patients &&
		`&:before{
			background: ${colors.Violet};
		}
    `};
	${({ TimelineHeading }) =>
		TimelineHeading &&
		`font-size: 16px;
		line-height: 19px;
		color: ${colors.BlueBase};
		font-family:  ${fontMedium.familyName};
		padding-right:10px;
		img{
			position: relative;
			top:2px;
			left:5px;
		}
	`};
	${({ textCase }) => textCase && `text-transform: ${textCase} !important;`}
	${({ h1 }) => h1 && `font-weight:normal;`}

	${({ center }) =>
		center &&
		`margin: auto !important;	position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translateX(-50%) translateY(-50%);
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);`}

	${({ Ellipsis }) =>
		Ellipsis && `white-space:nowrap; max-width: 100%; overflow: hidden; text-overflow: ellipsis;`};

	padding: ${(props) => props.padding};
	display: ${(props) => props.display};
`;

export default Heading;
