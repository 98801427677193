import styled from 'styled-components';
import { fontBold, fontMedium } from '../../assets/styles/typography';
import { colors } from '../colors';

const TableRow = styled.tr`
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0px;
	border: 1px solid ${colors.Border2};
	border-radius: 6px;
	cursor: pointer;
	:last-child {
		td {
			border-bottom: 0px;
		}
	}
	:hover {
		td {
			background: #e6f4ff !important;
		}
	}
	th {
		width: ${(props) => props.width}!important;
	}

	${({ noAction }) =>
		noAction &&
		`
		:hover{
            td{
			background: #ffffff !important;
            cursor:default !important;
            }
		}
	`};

	${({ Unread }) =>
		Unread &&
		`
		td{
			font-family: ${fontBold.familyName} !important;
			button{
				span{
					color: ${colors.Black};
				}
			}
		}
	`};

	${({ GroupHeader }) =>
		GroupHeader &&
		`
		td{
			background: #f8faff !important;
			span{
				background: #e4eaf9;
				padding:5px 15px;
				border-radius:30px;
			}
		}
    `};

	${({ GroupHeaderSeperator }) =>
		GroupHeaderSeperator &&
		`
		td{
			background: #f8faff !important;
			padding-top:8px!important;
			padding-bottom:8px!important;
			span{
				font-family: ${fontMedium.familyName},sans-serif;
				background:#f8faff;
				transition:ease-in all 0.4s;
			}
		}
		&:hover{
			span{
				background:#e6f4ff;
			}
		}
	`};

	${({ noBG }) =>
		noBG &&
		`
			th{
				background: transparent!important;
				padding-left:0px!important;
			}
    `};

	${({ subTH }) =>
		subTH &&
		`th { text-align: left;
				font-family: ${fontBold.familyName};
				font-weight: normal;
				background: #eef0f4;
				font-size: 15px;
				line-height: 18px;
				color: ${colors.Black};
				padding: 14px 15px 14px 33px;
                white-space: nowrap;
        }
    `};

	${({ DateSeperatorRow }) =>
		DateSeperatorRow &&
		`
		td{
			padding-left: 0px;
			padding-right: 0px;
			padding-bottom: 0px!important;
			border-bottom: 0px!important;
			background: ${colors.White}!importnt;
		}
`};

	${({ NodataContainer }) =>
		NodataContainer &&
		`background: ${colors.White}!important;
		&:hover{
			td{
				background: ${colors.White}!important;
			}
		}
		td{
			background: ${colors.White}!important;
		}
		`};

	@media screen and (max-width: 767px) {
		${({ ResponsiveNone }) => ResponsiveNone && `display: none!important; border: 1px solid #ccc;`};
	}
`;

export default TableRow;
