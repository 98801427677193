import styled from 'styled-components';

const SearchWrapper = styled.div`
	width: 400px;
	position: relative;
	transition: all ease 0.4s;
	input {
		height: 32px;
		padding: 4px 40px 4px 10px;
		transition: all ease 0.4s;
	}
	${({ chatSearch }) =>
		chatSearch &&
		`width: 100%;
		padding: 0px 15px 0;
		margin-bottom: 15px;
		input{
			height: 44px;
		}`}
	${({ FullSearch }) =>
		FullSearch &&
		`width: 100%;
		div{
			top: 5px;
		}
		input{
			height: 44px;
		}`}
	${({ Responsive }) =>
		Responsive &&
		`@media screen and (max-width: 767px){
			width:100%;
			margin-top: 10px;
			+ button{
				margin-top: 10px;
				+ button{
					margin-top: 10px;
				}
			}
		}

	`}
	${({ ResponsiveSmall }) =>
		ResponsiveSmall &&
		`@media screen and (max-width: 500px){
			width:100%;
		}

	`}
	${({ SearchClinicalDocs }) =>
		SearchClinicalDocs &&
		`margin-right:10px;
		@media screen and (max-width: 860px){
			width:300px;
		}
		@media screen and (max-width: 767px){
			width:100%;
			
		}

	`}
`;
export default SearchWrapper;
