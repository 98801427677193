import { getLocalStorageValueAsJSON } from '../pages/Login/login.store';
import { Get } from './firebaseService';

export const getClinicDetails = async () => {
	const clinicId = await getLocalStorageValueAsJSON('clinicId');

	const clinicData = await Get('Clinics', clinicId).then((querySnapshot) => ({
		data: querySnapshot.data(),
	}));
	return clinicData;
};
