import { useSelector, useDispatch } from 'react-redux';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import AppSpinner from './components/Spinner';
import LoggedOut from './components/LoggedOut';
import AppLayout from './components/AppLayout';

import PrivateRoutes from './routes/private.routes';
import PublicRoutes from './routes/public.routes';

import { APP_TOKEN, REFRESH_TOKEN } from './helpers/Constants';
import { preLogout } from './pages/Login/login.store';

const PageNotFound = lazy(() => import('./pages/PageNotFound'));

const AppRoutes = () => {
	// const dispatch = useDispatch();
	const [sessionOutModal, setSessionOutModal] = useState(false);

	const { isLoggedIn, authenticating, authenticated } = useSelector(({ AuthStore }) => {
		const { isLoggedIn, authenticating, authenticated } = AuthStore;
		return { isLoggedIn, authenticating, authenticated };
	});

	useEffect(() => {
		if (isLoggedIn) {
			// dispatch(getUserProfile());
			// dispatch(getNotifications({ params: { limit: 1000, offset: 0 } }));
		}
		return () => {};
		// eslint-disable-next-line
	}, [isLoggedIn]);

	useEffect(() => {
		function storageHandler({ key, newValue }) {
			if ([APP_TOKEN, REFRESH_TOKEN].includes(key) && !newValue) {
				setSessionOutModal(true);
				window.removeEventListener('storage', storageHandler);
			}
		}

		if (isLoggedIn) {
			window.addEventListener('storage', storageHandler);
		}

		return () => {};
	}, [isLoggedIn]);

	if (authenticating && !authenticated) return <AppSpinner />;
	return (
		<>
			{sessionOutModal && <LoggedOut />}
			<Router>
				<Switch>
					{PublicRoutes.map(({ component: Component, ...rest }, index) => (
						<Route key={index} {...rest}>
							<Suspense fallback={<AppSpinner />}>
								<Component />
							</Suspense>
						</Route>
					))}

					{PrivateRoutes.map((route, index) => (
						<PrivateRoute key={index} isLoggedIn={isLoggedIn} {...route} />
					))}
					<Route>
						<Suspense fallback={<AppSpinner />}>
							<PageNotFound />
						</Suspense>
					</Route>
				</Switch>
			</Router>
		</>
	);
};

const PrivateRoute = ({ component: Component, isLoggedIn, layoutProps, ...rest }) => {
	const dispatch = useDispatch();

	function appLogout() {
		dispatch(preLogout());
	}

	if (!isLoggedIn) {
		return <Redirect to="/" />;
	}

	return (
		<AppLayout isLoggedIn={isLoggedIn} appLogout={() => appLogout()} layoutProps={layoutProps}>
			<Suspense fallback={<AppSpinner />}>
				<Route {...rest} component={Component} />
			</Suspense>
		</AppLayout>
	);
};

export default AppRoutes;
