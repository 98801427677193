import React, { memo } from 'react';
// import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import SideBarList from './sidebar.router';
// import { getProfileDetails } from '../../pages/Login/login.selectors.js';

import { Icon, Label } from '../../stories';
import {
	SidebarListItems,
	SidebarSubMenu,
	SidebarSubMenuItems,
} from '../../stories/components/sidebar';
import { Roles } from '../../helpers/Constants';
import { getLocalStorageValueAsJSON } from '../../pages/Login/login.store';

function SideBarItem(props) {
	const { location = {} } = props;
	const activeItem = location.pathname;

	// const profileDetails = useSelector(({ AuthStore }) => getProfileDetails(AuthStore));
	// let { role = Roles.clinicAdmin, roles = [Roles.clinicAdmin] } = profileDetails;
	const { role = Roles.clinicAdmin } = getLocalStorageValueAsJSON('userDetails');

	return SideBarList.filter((list) => {
		const permissions = list.visibleTo || [];
		return permissions.length !== 0 ? permissions.includes(role) : false;
	}).map((link, index) => {
		const activeLink = activeItem === link.to ? { active: true } : {};
		const hasSubMenus = !!link.subMenus;

		return (
			<SidebarListItems
				key={index}
				{...activeLink}
				SubMenu={hasSubMenus}
				style={{ background: activeItem === link.to ? '#536DFE' : '' }}
			>
				{/* Currently, length > 1 means the user has more than 1 role */}
				{hasSubMenus && role.length > 1 && (
					<>
						<div>
							<Icon iconName={link.icon} />
							<Label>{link.label}</Label>
						</div>
						<SidebarSubMenu show>
							{link.subMenus.map((menu, i) => (
								<SidebarSubMenuItems
									key={`SIDE_MENU-${index}-${i}`}
									active={activeItem === menu.to}
								>
									<Link to={{ pathname: menu.to, refresh: true }}>{menu.label}</Link>
								</SidebarSubMenuItems>
							))}
						</SidebarSubMenu>
					</>
				)}
				{!hasSubMenus && (
					<Link to={{ pathname: link.to, refresh: true }}>
						<Icon iconName={link.icon} />
						<div>{link.label}</div>
					</Link>
				)}
			</SidebarListItems>
		);
	});
}

export default withRouter(memo(SideBarItem));
